import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter, NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { PUBLIC_LIBRARY } from 'helper/constants';
import VervoeLogo from '../VervoeLogo';
import SetupGuide from '../../../SetupGuide';
import AppcuesLaunchpadIcon from '../AppcuesLaunchpadIcon';
import AccountDropdown from './AccountDropdown';
import UpgradeButton from './UpgradeButton';
import { appCtx } from '../../../../appStore';

import useStyles from './styles';

const DesktopAppBar = observer(({ logoutUser }) => {
    const classes = useStyles();
    const { company, isLoadingCompany } = useContext(appCtx);
    const companyHasCandidatesPage = checkHasCompanyFeature(company, 'ALL_CANDIDATES_PAGE');
    const companyHasPublicLibrary = checkHasCompanyFeature(company, PUBLIC_LIBRARY);
    const companyHasCMS = checkHasCompanyFeature(company, 'CMS');
    const { freeTrialExpired, isSubscriptionPaused } = company;

    const isContentLibraryEnabled = process.env.REACT_APP_CMS_CONTENT_LIBRARY_ENABLED === 'true';

    const links = [{
        url: '/',
        element: <VervoeLogo />
    }];

    if (!freeTrialExpired && !isSubscriptionPaused) {
        links.push({
            url: '/my-assessments',
            element: 'My Assessments'
        });

        if (companyHasCMS) {
            if (isContentLibraryEnabled) {
                links.push({
                    url: '/content-library',
                    element: 'Content Library'
                });
            }
            links.push({
                url: '/assessment-requests',
                element: 'Assessment Requests'
            });
        }

        if (companyHasPublicLibrary) {
            links.push({
                url: '/marketplace',
                id: 'qa-interview-scripts',
                element: 'Assessment Library'
            });
        }
    }

    if (companyHasCandidatesPage && !freeTrialExpired && !isSubscriptionPaused) {
        links.push({
            url: '/all-candidates',
            id: 'qa-all-candidates',
            element: 'All Candidates'
        });
    }

    if (!isLoadingCompany && !freeTrialExpired && !isSubscriptionPaused) {
        links.push({
            url: '/hired',
            id: 'qa-hired',
            element: 'Hired'
        });
    }

    if (!freeTrialExpired) {
        links.push({
            url: '/account-insights',
            id: 'account-insights',
            element: 'Account Insights'
        });
    }

    return (
        <AppBar
            id="app_header"
            position="fixed"
            enableColorOnDark
            className={classes.appBar}
        >
            <Toolbar className={classes.toolbar}>
                <div className={classes.left}>
                    {
                        links.map(({ url, element, id }) => (
                            <NavLink
                                activeClassName={classes.desktopLinkActive}
                                className={classes.desktopLink}
                                to={url}
                                key={url}
                                id={id}
                            >
                                {element}
                            </NavLink>
                        ))
                    }
                </div>
                <div className={classes.right}>
                    { !isLoadingCompany && <SetupGuide /> }
                    <UpgradeButton />
                    <AppcuesLaunchpadIcon
                        className="u-mrg--rx3 u-mrg--lx1"
                    />
                    <AccountDropdown logoutUser={logoutUser} />
                </div>
            </Toolbar>
        </AppBar>
    );
});

export default withRouter(DesktopAppBar);
