import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import Dialog from 'libraries/Dialog';
import TextInput from 'libraries/TextInput';
import { appCtx } from 'components/appStore';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';
import LargeCheckIcon from 'img/large_check.svg';
import QuestionIcon from 'img/question.svg';
import TimerIcon from 'img/timer2.svg';
import { QuestionTypes } from 'helper/questionSettingsFunctions';
import Sidebar from './components/Sidebar';

import stylesJs from './styles';

const mockAssessmentData = {
    name: 'Growth Marketer',
    questions: 2,
    time: 90,
    skills: [
        {
            title: 'Data Analysis',
            description: 'Essential for understanding campaign performance, identifying trends, and making data-driven decisions'
        },
        {
            title: 'Digital Advertising',
            description: 'Key for leveraging platforms like Google Ads, Facebook, and others to drive targeted traffic and maximize ROI.'
        },
        {
            title: 'Creative Campaign Development',
            description: 'Crucial for crafting innovative and engaging marketing campaigns.'
        },
        {
            title: 'Communication',
            description: 'Key for leveraging platforms like Google Ads, Facebook, and others to drive targeted traffic and maximize ROI.'
        }
    ],
    questionTypes: [2, 4]
};

const renderQuestioType = (Icon, text) => (
    <div className="u-dsp--f u-ai--center">
        { Icon && (
            <Icon style={{
                width: '24px',
                height: '24px',
                marginRight: '15px'
            }}
            />
        ) }
        <span>{text}</span>
    </div>
);

const TTAiGeneratorDialog = ({ open, flashMessage, classes }) => {
    const [loading, setLoading] = useState(false);
    const [loadingPercent, setLoadingPercent] = useState(0);
    const [loadingText, setLoadingText] = useState('Analysing');
    const [currentStep, setCurrentStep] = useState(1);
    const [disableUpload, setDisableUpload] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [jobDescription, setJobDescription] = useState('');

    const { closeTTAiGeneratorDialog } = useContext(appCtx);

    useEffect(() => {
        if (!open) {
            setDefaultValues();
        }
    }, [currentStep, open]);

    useEffect(() => {
        setDisableUpload(jobDescription.length > 0);
    }, [jobDescription]);

    const setDefaultValues = () => {
        setLoading(false);
        setCurrentStep(1);
        setUploadedFile(null);
    };

    const onSubmit = async () => {
        setLoading(true);

        const formData = new FormData();
        if (jobDescription.length) {
            formData.append('description', jobDescription);
        }
        if (uploadedFile) {
            formData.append('file', uploadedFile);
        }
        setCurrentStep(0);
        // TODO: Add request for submit
        // Temp logic for now

        let interval;
        setLoadingPercent(0);

        interval = setInterval(() => {
            setLoadingPercent((prev) => {
                if (prev >= 50) {
                    setLoadingText('Generating Assessment');
                }
                if (prev + 3 >= 100) {
                    clearInterval(interval);
                    setLoading(false);
                    setLoadingText('Analysing');
                    setCurrentStep(2);
                    return 100;
                }
                return prev + 3;
            });
        }, 90);
    };

    const handleClose = () => {
        closeTTAiGeneratorDialog();
    };

    const handleJobDescription = (e) => {
        setJobDescription(e.target.value);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const maxFileSize = 10 * 1024 * 1024;
            if (file.size > maxFileSize) {
                flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
                return;
            }
            setUploadedFile(file);
            e.target.value = '';
        }
    };

    const onRestart = () => {
        setUploadedFile(null);
        setJobDescription('');
        setCurrentStep(1);
    };

    const disabledButton = !uploadedFile && !jobDescription.length;

    const renderActionComponent = () => {
        if (loading) {
            return null;
        }

        if (currentStep === 3) {
            return (
                <Button
                    color="primary"
                    variant="text"
                    className={classes.startAgainButton}
                    onClick={onRestart}
                >
                    Start again, create a new assessment for another role
                </Button>
            );
        }

        return (
            <>
                {
                    (Boolean(uploadedFile) || jobDescription.length > 0) && (
                        <Button
                            color="primary"
                            variant="text"
                            className={classes.nextButton}
                            onClick={onRestart}
                        >
                            Restart
                        </Button>
                    )
                }
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.nextButton}
                    onClick={onSubmit}
                    disabled={disabledButton}
                >
                    Next
                </Button>
            </>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            fullScreen
            actionComponent={renderActionComponent()}
            contentClassName={classes.root}
            actionClassName={classes.actions}
        >
            {loading && (
                <div className={classes.loading}>
                    <CircularProgress
                        size={60}
                    />
                    <div className="text">{loadingText}</div>
                    <div className="percent">{loadingPercent}%</div>
                </div>
            )}
            <div className={clsx(classes.wrapper, 'u-dsp--f')}>
                {
                    currentStep === 1 && (
                        <>
                            <Sidebar />
                            <div className={classes.content}>
                                <div className={classes.title}>Upload</div>
                                <div className={clsx(classes.uploadArea, disableUpload && 'disabled')}>
                                    { uploadedFile ? (
                                        <>
                                            <img src={LargeCheckIcon} className="u-mrg--bx2" alt="" />
                                            <div className="helperText">{uploadedFile.name} uploaded</div>
                                            <div>
                                                <label htmlFor="file-upload" className={classes.baseButton}>Re-upload</label>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <label htmlFor="file-upload" className="u-dsp--f u-jc--center u-mrg--tx4">
                                                <i className="c-img-dropzone__icon icon-upload-cloud" />
                                            </label>
                                            <div className="helperText">Please make sure the file is under 10MB in .pdf or .doc format</div>
                                        </>
                                    ) }
                                    <input
                                        className={classes.invisible}
                                        type="file"
                                        accept=".pdf,.doc,.docx,application/msword,application/pdf"
                                        disabled={disableUpload}
                                        onChange={handleFileUpload}
                                        hidden
                                        id="file-upload"
                                    />
                                </div>
                                <div className={classes.divider}>OR</div>
                                <div className={classes.title}>Paste Text</div>
                                <TextInput
                                    disabled={Boolean(uploadedFile)}
                                    classes={{ root: classes.textInput }}
                                    multiline
                                    rows={10}
                                    hasCounter
                                    maxLength={25000}
                                    label="Job Description"
                                    placeholder="Place text here"
                                    onChange={handleJobDescription}
                                    value={jobDescription}
                                    counterClassName={classes.counterClass}
                                    helperTextClassName={classes.helperInputClass}
                                />
                            </div>
                        </>
                    )
                }
                {
                    currentStep == 2 && (
                        <PreviewScript
                            onClose={closeTTAiGeneratorDialog}
                            interviewSlug="facility-manager"
                            open={currentStep == 2}
                            headerActions={(
                                <Button variant="contained" color="primary" className={classes.buttonReview} onClick={() => setCurrentStep(3)}>
                                    Review and Invite
                                </Button>
                            )}
                        />
                    )
                }
                {
                    currentStep == 3 && (
                        <div className={classes.readyWrapper}>
                            <div className="title">Your assessment is ready to go!</div>
                            <div className="assessmentName">{mockAssessmentData.name}</div>
                            <div className="table">
                                <div className="tableHeader">
                                    <div>
                                        <img src={QuestionIcon} alt="" />
                                        Number of Questions: <span>{mockAssessmentData.questions}</span>
                                    </div>
                                    <div>
                                        <img src={TimerIcon} alt="" />
                                        {mockAssessmentData.time} minutes
                                    </div>
                                </div>
                                <div className="tableBody">
                                    <div className="skills">
                                        <div>Skills Covered:</div>
                                        <ol>
                                            {
                                                mockAssessmentData.skills.map((el, idx) => (
                                                    <li key={idx} className="u-mrg--tx3">{el.title}: <span>{el.description}</span></li>
                                                ))
                                            }
                                        </ol>
                                    </div>
                                    <div className="questionTypes">
                                        <div>Question Types:</div>
                                        {
                                            mockAssessmentData.questionTypes.map((el, idx) => (
                                                <div key={idx} className="u-mrg--tx3">
                                                    {renderQuestioType(QuestionTypes[el].icon, QuestionTypes[el].label)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.footerButton}
                                onClick={() => {}}
                            >
                                Invite Candidates
                            </Button>
                            <div className="footerHelpText">
                                Find it anytime in your saved assessments
                            </div>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={() => {}}
                            >
                                Save for Later
                            </Button>
                        </div>
                    )
                }
            </div>
        </Dialog>
    );
};

export default withStyles(stylesJs)(withRouter(TTAiGeneratorDialog));
