import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink, withRouter } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import UploadUserPhotoAlt from 'components/photos/upload_user_photo_alt';
import { appCtx } from 'components/appStore';
import { goBackToAdmin } from 'requests/APIrequests';
import { checkHasCompanyFeature } from 'helper/commonFunctions';

import { ASSESSMENT_BRANDING, PERMISSIONS, PUBLIC_LIBRARY } from 'helper/constants';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import useStyles from './styles';

const MobileMenu = observer(({ open, onClose, logoutUser }) => {
    const {
        loggedUser, flashMessage, updateLoggedUser,
        company, isLoadingUser, isLoadingCompany
    } = useContext(appCtx);

    const classes = useStyles();
    const theme = useTheme();
    const [nestedOpen, setNestedOpen] = React.useState(false);
    const companyHasCandidatesPage = checkHasCompanyFeature(company, 'ALL_CANDIDATES_PAGE');
    const companyHasCMS = checkHasCompanyFeature(company, 'CMS');

    const handleClick = () => {
        setNestedOpen(!nestedOpen);
    };

    const { roleType } = loggedUser;
    const { freeTrialExpired, isSubscriptionPaused } = company;

    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    const hasCompanyBranding = checkHasCompanyFeature(company, ASSESSMENT_BRANDING);
    const companyHasPublicLibrary = checkHasCompanyFeature(company, PUBLIC_LIBRARY);
    const noAdminPermissions = ((roleType === 'team' && hasPermissionsFeature) || roleType === 'integration') && !isLoadingUser && !isLoadingCompany;
    const isContentLibraryEnabled = process.env.REACT_APP_CMS_CONTENT_LIBRARY_ENABLED === 'true';

    return (
        <Box component="nav">
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{ keepMounted: true }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 260 }
                }}
            >
                <div className={classes.drawerMobileMenu}>
                    <IconButton onClick={onClose} size="large">
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem className={classes.popoverHeader}>
                        <UploadUserPhotoAlt
                            loggedUser={loggedUser}
                            updateLoggedUser={updateLoggedUser}
                            flashMessage={flashMessage}
                            size={54}
                            hasUpload
                        />
                        <div className={classes.userInfo}>
                            <div className={classes.userInfoName}>{loggedUser.first_name} {loggedUser.last_name}</div>
                            <div className={classes.userInfoEmail}>{loggedUser.email}</div>
                        </div>
                    </ListItem>
                    {
                        (!freeTrialExpired && !isSubscriptionPaused) && (<>
                            <ListItem className="u-pdn--x0" button onClick={onClose}>
                                <NavLink
                                    activeClassName={classes.mobileLinkActive}
                                    className={classes.mobileLink}
                                    to="/my-assessments"
                                >
                                My Assessments
                                </NavLink>
                            </ListItem>
                            {
                                companyHasCMS && (
                                    <>
                                        {isContentLibraryEnabled && (
                                            <ListItem className="u-pdn--x0" button onClick={onClose}>
                                                <NavLink
                                                    activeClassName={classes.mobileLinkActive}
                                                    className={classes.mobileLink}
                                                    to="/content-library"
                                                    id="qa-interview-scripts"
                                                >
                                                    Content Library
                                                </NavLink>
                                            </ListItem>
                                        )}
                                        <ListItem className="u-pdn--x0" button onClick={onClose}>
                                            <NavLink
                                                activeClassName={classes.mobileLinkActive}
                                                className={classes.mobileLink}
                                                to="/assessment-requests"
                                                id="assessment-requests"
                                            >
                                                Assessment Requests
                                            </NavLink>
                                        </ListItem>
                                    </>
                                )
                            }
                            {
                                companyHasPublicLibrary && (
                                    <ListItem className="u-pdn--x0" button onClick={onClose}>
                                        <NavLink
                                            activeClassName={classes.mobileLinkActive}
                                            className={classes.mobileLink}
                                            to="/marketplace"
                                            id="qa-interview-scripts"
                                        >
                                        Assessment Library
                                        </NavLink>
                                    </ListItem>
                                )
                            }
                            {
                                companyHasCandidatesPage && (
                                    <ListItem className="u-pdn--x0" button onClick={onClose}>
                                        <NavLink
                                            activeClassName={classes.mobileLinkActive}
                                            className={classes.mobileLink}
                                            to="/all-candidates"
                                            id="qa-all-candidates"
                                        >
                                        All Candidates
                                        </NavLink>
                                    </ListItem>
                                )
                            }
                            <ListItem className="u-pdn--x0" button onClick={onClose}>
                                <NavLink
                                    activeClassName={classes.mobileLinkActive}
                                    className={classes.mobileLink}
                                    to="/hired"
                                    id="qa-hired"
                                >
                                    Hired
                                </NavLink>
                            </ListItem>
                            {
                                !freeTrialExpired && (
                                    <ListItem className="u-pdn--x0" button onClick={onClose}>
                                        <NavLink
                                            activeClassName={classes.mobileLinkActive}
                                            className={classes.mobileLink}
                                            to="/account-insights"
                                            id="account-insights"
                                        >
                                            Account Insights
                                        </NavLink>
                                    </ListItem>
                                )
                            }
                    </>)
                    }
                </List>
                <Divider />
                {
                    (!freeTrialExpired && !isSubscriptionPaused) && (
                    <>
                        <List>
                            <ListItem className={classes.mobileLink} button onClick={handleClick}>
                                Resources
                                {nestedOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding className={classes.nestedList}>
                                    <a href="https://vervoe.zendesk.com/hc/en-us/categories/360002338852-Hiring-Resources" target="_blank" rel="noopener noreferrer">
                                        <ListItem className={classes.nestedItem} onClick={onClose}>
                                            <div className={classes.nestedItemHeader}>Guides</div>
                                            Hiring guides to help you create the perfect assessment
                                        </ListItem>
                                    </a>
                                    <a href="https://vervoe.zendesk.com/hc/en-us/categories/360001794451-Video-Tutorials" target="_blank" rel="noopener noreferrer">
                                        <ListItem className={classes.nestedItem} onClick={onClose}>
                                            <div className={classes.nestedItemHeader}>Tutorials</div>
                                            Get the best out of Vervoe with these curated tutorials
                                        </ListItem>
                                    </a>
                                    <a href="https://vervoe.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">
                                        <ListItem className={classes.nestedItem} onClick={onClose}>
                                            <div className={classes.nestedItemHeader}>Help Center</div>
                                            Guides for every part of your Vervoe experience
                                        </ListItem>
                                    </a>
                                    <a href="https://vervoe.com/demo" target="_blank" rel="noopener noreferrer">
                                        <ListItem className={classes.nestedItem} onClick={onClose}>
                                            <div className={classes.nestedItemHeader}>Request a demo</div>
                                            Want to know more? Get in touch with our expert sales team.
                                        </ListItem>
                                    </a>
                                </List>
                            </Collapse>
                        </List>
                        <Divider />
                    </>
                    )
                }
                <List>
                    <ListItem className="u-pdn--x0" button onClick={onClose}>
                        <NavLink
                            activeClassName={classes.mobileLinkActive}
                            className={classes.mobileLink}
                            to="/my-account"
                            id="qa-my-account"
                        >
                            My Account
                        </NavLink>
                    </ListItem>
                    {
                        !noAdminPermissions && (
                        <>
                            <ListItem className="u-pdn--x0" button onClick={onClose}>
                                <NavLink
                                    activeClassName={classes.mobileLinkActive}
                                    className={classes.mobileLink}
                                    to="/company-account"
                                    id="qa-company-account"
                                >
                                    Company Account
                                </NavLink>
                            </ListItem>
                            { hasCompanyBranding && (
                                <ListItem className="u-pdn--x0" button onClick={onClose}>
                                    <NavLink
                                        activeClassName={classes.mobileLinkActive}
                                        className={classes.mobileLink}
                                        to="/branding-details"
                                        id="qa-branding-details"
                                    >
                                    Branding Details
                                    </NavLink>
                                </ListItem>
                            )}
                            {(!freeTrialExpired && !isSubscriptionPaused) && (
                                <ListItem className="u-pdn--x0" button onClick={onClose}>
                                    <NavLink
                                        activeClassName={classes.mobileLinkActive}
                                        className={classes.mobileLink}
                                        to="/team-settings"
                                    >
                                        Team
                                    </NavLink>
                                </ListItem>
                            )}
                        </>
                        )
                    }
                    <ListItem className="u-pdn--x0" button onClick={onClose}>
                        <NavLink
                            activeClassName={classes.mobileLinkActive}
                            className={classes.mobileLink}
                            to="/notifications-settings"
                            id="qa-notification-settings"
                        >
                            Notifications
                        </NavLink>
                    </ListItem>
                    {
                        !noAdminPermissions && (
                        <>
                            <ListItem className="u-pdn--x0" button onClick={onClose}>
                                <NavLink
                                    activeClassName={classes.mobileLinkActive}
                                    className={classes.mobileLink}
                                    to="/billing"
                                    id="qa-subscription-management"
                                >
                                    Current Plan and Billing Details
                                </NavLink>
                            </ListItem>
                            <ListItem className="u-pdn--x0" button onClick={onClose}>
                                <SubscriptionsLink
                                    label="Plan Types"
                                    activeClassName={classes.mobileLinkActive}
                                    className={classes.mobileLink}
                                    segmentLocation="mobile menu link"
                                />
                            </ListItem>
                            {
                                (!freeTrialExpired && !isSubscriptionPaused) && (
                                    <ListItem className="u-pdn--x0" button onClick={onClose}>
                                        <NavLink
                                            activeClassName={classes.mobileLinkActive}
                                            className={classes.mobileLink}
                                            to="/integrations-management"
                                            id="qa-integrations-management"
                                        >
                                            Integrations
                                        </NavLink>
                                    </ListItem>
                                )
                            }
                            {
                                company?.companySettings?.chatbotWidget && (
                                    <ListItem className="u-pdn--x0" button onClick={onClose}>
                                        <NavLink
                                            activeClassName={classes.mobileLinkActive}
                                            className={classes.mobileLink}
                                            to="/chatbot-settings"
                                            id="chatbot-settings"
                                        >
                                            Chatbot Settings
                                        </NavLink>
                                    </ListItem>
                                )
                            }
                        </>
                        )
                    }
                    <ListItem className="u-pdn--x0" button onClick={onClose}>
                        <a
                            href="https://vervoe.zendesk.com/hc/en-us"
                            target="_blank"
                            rel="noopener noreferrer"
                            id="qa-help"
                            className={classes.mobileLink}
                        >
                            Help
                        </a>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={logoutUser} id="qa-sign-out" className={classes.mobileLink}>
                        Sign out
                    </ListItem>
                    {
                        loggedUser.backToAdmin && (
                            <ListItem button onClick={goBackToAdmin} className={classes.mobileLink}>
                                Back to Admin
                            </ListItem>
                        )
                    }
                </List>
            </Drawer>
        </Box>
    );
});

export default withRouter(MobileMenu);
