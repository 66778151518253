const styles = theme => ({
    nextButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        paddingLeft: 40,
        paddingRight: 40,
        height: 50
    },
    root: {
        overflowX: 'hidden',
        padding: 0,
        margin: 0
    },
    wrapper: {
        height: '100%'
    },
    sidebar: {
        width: '40%',
        background: theme.palette.blue[50],
        height: '100%',
        padding: '135px 80px 90px 70px',
        '& b': {
            fontWeight: 800
        },
        '& a': {
            color: theme.palette.blue[500],
            fontWeight: 600,
            textDecoration: 'underline'
        },
        color: theme.palette.grey[900],
        '& .title': {
            fontSize: 34,
            fontWeight: 600,
            [theme.breakpoints.down('lg')]: {
                fontSize: 22
            }
        },
        '& .steps': {
            fontSize: 27,
            [theme.breakpoints.down('lg')]: {
                fontSize: 18
            }
        },
        [theme.breakpoints.down('lg')]: {
            width: '30%',
            padding: '80px 40px 40px 30px'
        }
    },
    content: {
        padding: '100px',
        flex: 1
    },
    invisible: {
        opacity: 0,
        position: 'absolute',
        left: -9999
    },
    title: {
        fontSize: 30,
        fontWeight: 700,
        color: theme.palette.grey[900],
        marginBottom: 25
    },
    uploadArea: {
        border: `2px solid ${theme.palette.grey[300]}`,
        width: '100%',
        height: 270,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& i': {
            cursor: 'pointer',
            color: '#545556'
        },
        '&.disabled i': {
            opacity: 0.6,
            cursor: 'default'
        },
        '&:not(.disabled) i:hover': {
            opacity: 0.8
        },
        '& .helperText': {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.grey[500]
        }
    },
    baseButton: {
        color: theme.palette.blue[800],
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        display: 'block',
        cursor: 'pointer',
        margin: '0 auto'
    },
    uploadButton: {
        color: theme.palette.blue[800],
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        display: 'block',
        margin: '0 auto'
    },
    divider: {
        fontSize: 37,
        fontWeight: 600,
        color: theme.palette.grey[900],
        margin: '30px 0',
        textAlign: 'center'
    },
    textInput: {
        minHeight: 300
    },
    counterClass: {
        color: theme.palette.grey[600],
        fontSize: 10,
        fontWeight: 400,
        textAlign: 'left',
        marginLeft: -13
    },
    helperInputClass: {
        '& .helperText': {
            display: 'none'
        }
    },
    actions: {
        padding: '20px 70px',
        [theme.breakpoints.down('md')]: {
            padding: '30px'
        },
        '& button': {
            height: 40
        }
    },
    loading: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: 36,
        '& .text': {
            marginTop: 40,
            color: theme.palette.grey[900]
        },
        '& .percent': {
            color: theme.palette.blue[500],
            marginTop: 18
        }
    },
    readyWrapper: {
        background: theme.palette.blue[50],
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .title': {
            fontSize: 35,
            fontWeight: 800,
            color: theme.palette.grey[900]
        },
        '& .assessmentName': {
            fontSize: 30,
            fontWeight: 700,
            color: theme.palette.grey[900]
        },
        '& .table': {
            maxWidth: 950,
            fontWeight: 700,
            color: theme.palette.grey[900],
            '& .tableHeader': {
                display: 'flex',
                alignItems: 'center',
                background: theme.palette.grey[200],
                marginTop: 50,
                padding: '16px 38px',
                fontSize: 16,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                gap: 290,
                '& img': {
                    marginTop: -5,
                    marginRight: 10
                }
            },
            '& .tableBody': {
                fontSize: 14,
                lineHeight: '26px',
                display: 'flex',
                background: theme.palette.grey[50],
                width: '100%',
                padding: '30px 45px',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                '& .skills': {
                    width: '60%',
                    borderRight: `1px solid ${theme.palette.grey[300]}`,
                    paddingRight: 30,
                    '& ol': {
                        paddingLeft: 15
                    }
                },
                '& .questionTypes': {
                    paddingLeft: 30
                }
            },
            '& span': {
                fontWeight: 400
            }
        },
        '& .footerHelpText': {
            width: 175,
            fontSize: 14,
            fontWeight: 400,
            textAlign: 'center',
            margin: '34px 0 6px',
            lineHeight: '24px'
        }
    },
    footerButton: {
        marginTop: 48
    },
    startAgainButton: {
        margin: '0 auto'
    }
});

export default styles;
