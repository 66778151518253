import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import DeleteAssessmentDialog from 'components/dialogs/DeleteAssessmentDialog';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';

import GeneralEvents from 'events/GeneralEvents';
import LibraryEvents from 'events/LibraryEvents';
import AdvancedAssessmentsContent from './components/AdvancedAssessmentsContent';

const PrivateBoardItem = ({
    assessment, history, location,
    removeAssessment, handleDuplicate,
    setAssessmentFolder
}) => {
    const [openPreviewScript, setOpenPreviewScript] = useState(false);
    const [openDeleteScript, setOpenDeleteScript] = useState(false);

    const { slug, active, totalCandidates, uuid, name, questionGroups, numberOfCompletions, countQuestions, 
        permissions, generatedViaAi } = assessment;

    const launchSegmentTTCustomized = () => {
        const data = {
            ttId: uuid,
            ttName: name,
            questionsNumber: countQuestions,
            questionGroupsNumber: questionGroups.length
        };
        if (questionGroups.length) {
            data.questionGroups = questionGroups.map(item => item.title);
        }
        data.assessmentActive = active;
        data.totalCompletionsNumber = numberOfCompletions;
        data.totalCandidatesNumber = totalCandidates;
        LibraryEvents.PRIVATE_TT_CUSTOMIZED(data);
    };

    const handleGoToAssessment = () => {
        if(generatedViaAi) {
            goToAssessmentInvite();
            return;
        }
        if (!active && (permissions && !permissions.edit)) {
            goToAssessmentResults();
            return;
        }
        if (active && (numberOfCompletions || (permissions && !permissions.edit))) goToAssessmentResults();
        else goToAssessment();
    };

    const goToAssessmentInvite = () => {
        history.push(`/script/invite/${slug}`);
        launchSegmentTTCustomized();
    }

    const goToAssessment = () => {
        history.push(`/script/edit/${slug}`);
        launchSegmentTTCustomized();
    };

    const goToAssessmentResults = () => {
        history.push(`/script/select/${slug}`);
        launchSegmentTTCustomized();
    };

    const goToAssessmentSettings = () => {
        history.push({ pathname: `/script/settings/${slug}`, state: { from: location.pathname } });
        launchSegmentTTCustomized();
    };

    const handlePreviewScript = () => {
        GeneralEvents.TT_PREVIEWED({ ttId: uuid, ttName: name, publicLibrary: false, activeAssessment: active });
        setOpenPreviewScript(!openPreviewScript);
    };

    const handleDeleteScript = () => {
        setOpenDeleteScript(!openDeleteScript);
    };

    return (
        <>
            <AdvancedAssessmentsContent
                assessment={assessment}
                handleDuplicate={handleDuplicate}
                handlePreviewScript={handlePreviewScript}
                goToAssessment={handleGoToAssessment}
                handleDeleteScript={handleDeleteScript}
                goToAssessmentSettings={goToAssessmentSettings}
                removeAssessment={removeAssessment}
                launchSegmentTTCustomized={launchSegmentTTCustomized}
                setAssessmentFolder={setAssessmentFolder}
            />
            <PreviewScript
                {...{
                    onClose: setOpenPreviewScript,
                    interviewSlug: slug,
                    open: openPreviewScript,
                    uuid
                }}
            />
            <DeleteAssessmentDialog
                open={openDeleteScript}
                interview={assessment}
                active={active}
                onDelete={removeAssessment}
                onClose={handleDeleteScript}
            />
        </>
    );
};

export default withRouter(PrivateBoardItem);
