import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Checkbox from 'libraries/Checkbox';
import Button from '@mui/material/Button';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import PanelHeader from 'components/assessments_pages/asessment_settings_components/SectionHeader';
import { appCtx } from 'components/appStore';
import RegenerateComponent from 'components/assessments_pages/RegenerateComponent';

import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import useStyles from './styles';

const MenuItem = withRouter(({ header, location, match, history, path, subheader, disabled }) => {
    const classes = useStyles();
    const { scriptSlug } = match.params;
    const url = path.replace(':scriptSlug', scriptSlug);
    const onClick = () => { !disabled && history.push(url); };
    const isActive = location.pathname === url;
    return (
        <div
            role="presentation"
            className={clsx(classes.menuItem, isActive && classes.menuItemActive, disabled && classes.menuItemDisabled)}
            onClick={onClick}
        >
            <p className={clsx(classes.menuItemHeader, isActive && classes.menuItemHeaderActive)}>{header}</p>
            <p className={classes.menuItemSubheader}>{subheader}</p>
        </div>
    );
});

const SideBar = observer(({ routes, loadEditScript, history, settings: settingsProps, audition, changeAssessmentSettings }) => {
    const [settings, setSettings] = useState({
        equipmentCheckForced: false,
        hideCompleteLaterButton: false,
        eeocEnabled: false
    });

    useEffect(() => {
        if (settingsProps) {
            setSettings(settingsProps);
        }
    }, [settingsProps]);

    const classes = useStyles();
    const { company } = useContext(appCtx);
    const canCompleteLater = company.companySettings.apiIntegration;

    const { equipmentCheckForced, hideCompleteLaterButton, eeocEnabled } = settings;

    const handleChange = (e, setting) => {
        const { checked } = e.target;
        setSettings({ ...settings, [setting]: checked });
        changeAssessmentSettings({ [setting]: checked }, () => {
            if (setting === 'eeocEnabled') {
                AssessmentSettingsEvents.EEOC_ENABLED({
                    enabled: checked,
                    ttUUID: audition.uuid,
                    ttName: audition.name
                });
            }
        });
    };

    const onSuccessRegenerate = () => {
        loadEditScript(history);
        history.push(`/script/edit/${audition.slug}`);
    };

    return (
        <div className={classes.leftPanel}>
            <PanelHeader className={classes.panelHeader}>Assessment Settings</PanelHeader>
            {
                routes.slice(0, 3).map(item => (
                    <MenuItem {...item} />
                ))
            }
            <div className={classes.menuItemEquipment}>
                <p className={clsx(classes.menuItemHeader, 'u-mrg--tx4 u-mrg--bx3')}>Invitation Settings</p>
                <p className={classes.menuItemHeaderEquipment}>Equipment, Compliance and Accessibility</p>
                <Checkbox
                    label={(
                        <span>
                            Mandatory equipment check
                            <InfoTooltip
                                id="edit_form_equipment_check_id"
                                position="right"
                                isBlack
                                className={classes.equipmentTooltip}
                                text="Candidates will be required to test their audio and video equipment before starting the assessment."
                            />
                        </span>
                    )}
                    name="require_equipment_check"
                    onChange={e => handleChange(e, 'equipmentCheckForced')}
                    checked={equipmentCheckForced}
                />
                <Checkbox
                    label={<>
                        <span className="u-mrg--rx2">Enable EEOC Checklist</span>
                        <a
                            className={classes.link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://help.vervoe.com/hc/en-us/articles/10244446378900-Enable-EEOC-questionnaire-on-assessment"
                        >
                            learn more
                        </a>
                    </>}
                    checked={eeocEnabled}
                    onChange={e => handleChange(e, 'eeocEnabled')}
                />
                {canCompleteLater && (
                    <Checkbox
                        label={<span>Hide &#8216;Complete later&#8217; button from assessment</span>}
                        className="u-mrg--tx1"
                        name="hide_complete_later_button"
                        onChange={e => handleChange(e, 'hideCompleteLaterButton')}
                        checked={hideCompleteLaterButton}
                    />
                )}
            </div>
            {
                routes.slice(3).map(item => (
                    <MenuItem {...item} />
                ))
            }


            <div className={classes.regenerateWrapper}>
                <div className={classes.regenerateItem}>
                    <RegenerateComponent
                        assessment={audition}
                        onSuccess={onSuccessRegenerate}
                        labelComp={(
                            <Button
                                color="primary"
                                className={classes.settingsButton}
                            >
                                Re-generate Assessment
                            </Button>
                        )}
                    />
                </div>
            </div>
        </div>
    );
});

export default withRouter(SideBar);
