import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import DrawerPageWrapper from 'components/layout/DrawerPageWrapper';
import ProtectedWithAssessmentFlow from 'components/routes/protected_with_assessment_flow';
import Main from './components/Main'; // need to have a separate component to pass properties from DrawerPageWrapper
import DetailsPage from './components/DetailsPage';
import SkillsGroupPage from './components/SkillsGroupPage';
import ChatbotPage from './components/ChatbotPage';
import DeadlinePage from './components/DeadlinePage';
import CommunicationPage from './components/CommunicationPage';
import InvitationPreviewPage from './components/InvitationPreviewPage';


const AssessmentSettings = observer(({ history, location, match, audition }) => {
    const { interviewSlug } = match.params;

    useEffect(() => {
        if (location.pathname === `/script/settings/${interviewSlug}`) {
            history.push({ pathname: `/script/settings/${interviewSlug}/details`, state: { from: location.pathname } });
        }
    }, []);

    const routes = [{
        path: '/script/settings/:scriptSlug/details',
        Component: DetailsPage,
        Type: ProtectedWithAssessmentFlow,
        header: 'Assessment Details',
        subheader: 'View and update your standard assessment details'
    }, {
        path: '/script/settings/:scriptSlug/skill-settings',
        Type: ProtectedWithAssessmentFlow,
        Component: SkillsGroupPage,
        header: 'Skill Group Settings',
        subheader: 'View and configure your skill group settings'
    }, {
        path: '/script/settings/:scriptSlug/chatbot-settings',
        Type: ProtectedWithAssessmentFlow,
        Component: ChatbotPage,
        header: 'Chatbot Settings',
        subheader: 'View and configure screening chatbot'
    }, {
        path: '/script/settings/:scriptSlug/invitation-preview',
        header: 'Invitation Preview Settings',
        Component: InvitationPreviewPage,
        disabled: !audition.active,
        subheader: 'Customise text on email invitation'
    }, {
        path: '/script/settings/:scriptSlug/limit-and-deadline',
        header: 'Time Limit and Deadline',
        Component: DeadlinePage,
        subheader: 'View and configure timer and deadline details'
    }, {
        path: '/script/settings/:scriptSlug/candidate-communication',
        header: 'Candidate Communication',
        Component: CommunicationPage,
        disabled: !audition.active,
        headerPage: 'Candidate Communication Settings',
        subheader: 'Email candidate to remind and inform'
    }];


    return (
        <DrawerPageWrapper>
            <Main routes={routes} />
        </DrawerPageWrapper>
    );
});

export default AssessmentSettings;
