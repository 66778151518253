import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import styles from '../../styles';

const Sidebar = ({
    classes
}) => (
    <div className={clsx(classes.sidebar, 'u-dsp--f u-jc--sb u-fdir--column')}>
        <div className="title">Upload or copy and paste your job description to start</div>
        <div className="steps">
            <div><b>How it works</b></div>
            <div><b>Step 1:</b> Upload job description</div>
            <div><b>Step 2:</b> <a href="https://help.vervoe.com/hc/en-us/articles/33459197657108-AI-Assessment-Builder" target="_blank">Skills Taxonomy</a> </div>
            <div><b>Step 3:</b> Review assessment</div>
            <div><b>Step 4:</b> Invite Candidates</div>
        </div>
    </div>
);

export default withStyles(styles)(Sidebar);
